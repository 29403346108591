import gql from 'graphql-tag';

export const OPERATOR_CONTEXT = gql`
    {
        operatorContext{
            operatorID
            name
            surname
            nameInChatbox
            email 
            img
            shortcut
            color
            initialStatus
        }
    }
`;
export const OPERATOR_LAST_SELECTED_ACCOUNT = gql`
    
    query OperatorLastSelectedAccount($operatorID:ID){      
        operatorLastSelectedAccount(operatorID:$operatorID){
            accountID
            name
            surname
            company
        }
    }
`;
export const OPERATOR_ACCOUNT = gql`
    
    query OperatorAccount($operatorID:ID,$accountID:ID){      
        operatorAccount(operatorID:$operatorID,accountID:$accountID){
            accountID
            name
            surname
            company
            roleID
        }
    }
`;

export const GET_INITIAL_DATA = gql`
    query AllOperators($accountID:ID){
        allOperators(accountID:$accountID,withDeleted:true){
            operatorID
            name
            surname
            nameInChatbox
            shortcut
            img
            color
        }
    }
`;


