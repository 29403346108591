/*
    routování aplikace
*/
import React, { Component } from 'react';
import {Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import Login from '../Modules/Auth/Components/Login';
import NoMatch from './NoMatch';
import Registration from '../Modules/Auth/Components/Registration';
import ResetPassword from '../Modules/Auth/Components/ResetPassword';
import SetNewPassword from '../Modules/Auth/Components/SetNewPassword';
import Account from '../Modules/Account/Components/Account';


class Routes extends Component {
	
  render() {
	  	  
    return (
      <BrowserRouter>
        <Switch>
        		
					<Route exact path="/">
							<Redirect to="/account" />
					</Route>	
	        <Route exact path='/login' component={Login}></Route>
					<Route path='/registration' component={Registration}></Route>
					<Route path='/reset-password' component={ResetPassword}></Route>
					<Route path='/set-new-password/:resetPasswordHash' component={SetNewPassword}></Route>
          <Route exact path='/account' component={Account}></Route>
          <Route path='/account/:accountID' component={Account}></Route>
          
	        <Route component={NoMatch} />

	      </Switch>
      </BrowserRouter>
    );
  }
  
}
export default Routes;