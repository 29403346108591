import React from 'react';
import translate from '../../Translations/index';


const Automatization = (props) => {

    return(
        <div className=""></div>
    );

}

export default Automatization;