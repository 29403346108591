import gql from 'graphql-tag';


export const GET_CONVERSATIONS = gql`
    query Conversations($accountID:ID,$filters:ConversationFilterData,$order:ConversationOrderByData,$offset:Int,$limit:Int,$operatorID:ID){
        conversations(accountID:$accountID,filters:$filters,order:$order,offset:$offset,limit:$limit,operatorID:$operatorID){
            conversationID
            isInternalConversation
            visitorID
            conversationStateID
            waitingForResponseFrom
            visitor{
                visitorID
                name
                shortcut
                status
                color
            }
            operators{
                operatorID
                name
                surname
                nameInChatbox
                img
                shortcut
                color
            }
            lastMessage{
                text
                createdDate
                fromOperator
            }
            unreadMessageCount
        }
    }
`;
export const GET_CONVERSATION = gql`
    query Conversation($conversationID:ID,$operatorID:ID,$filterData:MessagesFilterData){
        conversation(conversationID:$conversationID,operatorID:$operatorID){
            conversationID
            accountID
            visitorID
            chatboxID
            unreadMessageCount
            conversationStateID
            visitor{
                visitorID
                accountID
                name
                shortcut
                email
                status
                color
                visitCount
                lastVisit  
                city          
                countryCode
                countryName
                continentCode
                continentName
                latitude
                longitude
                pageTitle
                referrer
                osName
                osVersion
                browserName
                browserVersion
                isMobile
                isTablet
                ip
                
                visitedPages{
                    url
                    dateAdd
                }
            }
            chatbox{                
                chatboxID
                name
                lang
                systemOperatorName
                systemOperatorPhoto
            }
        }
        messages(conversationID:$conversationID,filterData:$filterData){  
            messageID          
            messageTypeID
            text
            fromOperator
            operatorID
            visitorID
            createdDate
            deliveredDate            
            ratingSelected
            ratedValue
            readBy{
                visitorID
                operatorID
                readDate
            }
            files{
                fileID
                accountID
                name
                size
                ico
            }
        }
        conversationOperators(conversationID:$conversationID){            
            operatorID          
            name
            surname
            nameInChatbox
            shortcut
            color
            img
            status
        }
        tasks(conversationID:$conversationID){            
            taskID
            conversationID
            text
            isCompleted
            operatorAdd
            dateAdd
        }
        notes(conversationID:$conversationID){            
            noteID
            conversationID
            text
            operatorAdd
            dateAdd
        }
    }
`;

export const GET_CONVERSATION_MESSAGES = gql`
    query ConversationMessages($conversationID:ID,$filterData:MessagesFilterData){        
        messages(conversationID:$conversationID,filterData:$filterData){  
            messageID          
            messageTypeID
            text
            fromOperator
            operatorID
            visitorID
            createdDate
            deliveredDate                    
            ratingSelected
            ratedValue
            readBy{
                visitorID
                operatorID
                readDate
            }
            files{
                fileID
                accountID
                name
                size
                ico
            }
        }
    }
`;

export const UPLOAD_FILES = gql`
    mutation UploadFiles($addFilesData:AddFilesData) {
        uploadFiles(addFilesData:$addFilesData){
            fileID
            tmpID
            accountID
            name
            size
            ico
        }
    }
`;  
export const DELETE_FILE = gql`
    mutation DeleteFile($fileID:ID) {
        deleteFile(fileID:$fileID)
    }
`;
export const MOVE_CONVERSATIONS_TO_POSTPONED = gql`
    mutation MoveConversationsToPostponed($conversationIDs:[ID]) {
        moveConversationsToPostponed(conversationIDs:$conversationIDs)
    }
`;  
