import React from 'react';
import { operatorVar } from '../../../Library/Cache/operatorCache';


const Operators = (props) => {
    
	const operatorData         = operatorVar();

    return(
        
        <div className="d-flex flex-grow-1 flex-column" >
            Operators 
        </div>
    );

}

export default Operators;