import { makeVar } from '@apollo/client';
  
export const operatorVar = makeVar({
    status:"offline",
    operatorID:null,
    accountID:null,
    name:"",
    surname:"",
    nameInChatbox:"",
    roleID:null,
    email:"",
    img:"",
    shortcut:"",
    color:""
});

export const operatorCache = {
    get: () => {
        return operatorVar();
    },
    set: (data) => {
        const newData = { 
            ...(data.status 		&& { status: data.status }),
            ...(data.operatorID 	&& { operatorID: data.operatorID }),
            ...(data.accountID 	    && { accountID: data.accountID }),
            ...(data.name 	        && { name: data.name }),
            ...(data.surname 	    && { surname: data.surname }),
            ...(data.nameInChatbox 	&& { nameInChatbox: data.nameInChatbox }),            
            ...(data.roleID 	    && { roleID: data.roleID }),
            ...(data.email 	        && { email: data.email }),
            ...(data.img 	        && { img: data.img }),
            ...(data.shortcut 	    && { shortcut: data.shortcut }),
            ...(data.color 	        && { color: data.color }),
        }
        operatorVar({...operatorVar(),...newData});
    }
}