import React,{useState } from 'react';

const Switcher = (props) => {
    const [active,setActive] 	        = useState(!!props.active ? !!props.active : false);


    const switchOnOff = () => {
      setActive(!active);
      props.onChange && props.onChange(!active);
    }

    return (   
      <div className={`switcher ${!!props.className ? props.className : ""} ${!!active ? "active" : ""}`} onClick={() => switchOnOff()} >
        <div className='circle'></div>
      </div>
    );
}


export default Switcher;
