import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import Loading from '../../../../GlobalComponents/Loading';
import ModalNotification from '../../../../GlobalComponents/ModalNotification';
import Switcher from '../../../../GlobalComponents/Switcher';
import { operatorCache } from '../../../../Library/Cache/operatorCache';
import { addNotification, useAdminRights } from '../../../../Library/helper';
import translate from '../../../Translations/index';
import { ADD_EDIT_CHATBOX, DELETE_CHATBOX, GET_CHATBOXES } from '../../Queries/settings';
import AddChatbox from './AddChatbox';


const ChatboxList = (props) => {

	const operatorData                  = operatorCache.get();
    const {loading:roleCheckLoading}    = useAdminRights(operatorData?.roleID,["1","2"],`/account`);

    let { path, url }                   = useRouteMatch();
	const history 			            = useHistory();
    const [showAdd,setShowAdd]          = useState(false);
    const [deleteInfo,setdDeleteInfo]   = useState({
        show:false,
        title:"",
        deleteID:null
    });
    
    
    const { data,loading, refetch } = useQuery(GET_CHATBOXES,{
        fetchPolicy:"network-only",
        notifyOnNetworkStatusChange: true,
        variables:{
            accountID:operatorData.accountID
        },
        onCompleted(data){   
        },						
        onError(err){
            console.log(err);
			addNotification("danger",err.toString(),translate.t("Error"));
        }
    });
    
    const [switchActiveChatbox] = useMutation(ADD_EDIT_CHATBOX,{
        onCompleted(data){			

        },onError(err){            
			addNotification("danger",err.message ? err.message : err.toString(),translate.t("Error"));
        }
    })
    const [deleteCheckbox] = useMutation(DELETE_CHATBOX,{
        onCompleted(data){			
            refetch();
            addNotification("success",translate.t("ChatboxSuccessfullyDeleted"),translate.t("SuccessfullyDeleted"));

        },onError(err){            
			addNotification("danger",err.message ? err.message : err.toString(),translate.t("Error"));
        }
    })

    const initiateDelete = (deleteID,title) => {

        setdDeleteInfo({show:true,deleteID,title});
    }

    const deleteConfirmation = (status) => {
        if(status){
            deleteCheckbox({variables:{chatboxID:deleteInfo.deleteID}});
        }
        setdDeleteInfo({...deleteInfo,show:false});
    }
    const chatboxAdded = (addedChatbox) => {
        history.push(url + "/" + addedChatbox.chatboxID + "/appearance");
    }
    
    return(
        <>
        
            {!roleCheckLoading && !loading ?
                <>
                    <div className='page'>
                        {data?.allChatboxes?.length > 0 ?
                            <>
                                <h1>{translate.t("Chatbox")}</h1>
                                <table className='full-width hoverable'>
                                    <thead>
                                        <tr>
                                            <th className='left'>{translate.t("ListChatboxName")}</th>
                                            <th className='left'>{translate.t("WebURL")}</th>
                                            <th>{translate.t("MessageCountToday")}</th>
                                            <th>{translate.t("MessageCountTotal")}</th>
                                            <th className='right'>{translate.t("Active")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.allChatboxes?.map((item,index) => (
                                            <tr key={index}>
                                                <td className='left'>
                                                    <div className='controlls'>
                                                        <i className="las la-square-full chatbox-color" style={{color:item.mainColor}}></i>
                                                        {item.name}
                                                        <div className='hidden-content'>
                                                            <i onClick={() => history.push(path + "/" + item.chatboxID)} className="las la-pencil-alt icon"></i>
                                                            <i onClick={() => initiateDelete(item.chatboxID,item.name)} className="las la-trash-alt icon"></i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='left'>{item.webUrl}</td>
                                                <td></td>
                                                <td></td>
                                                <td className='right'><Switcher className="ml-auto" active={item.active} onChange={(active) => switchActiveChatbox({variables:{addChatboxData:{chatboxID:item.chatboxID,active:active ? 1 : 0}}})} /></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className='list-controls'>
                                    <button className='btn blue' onClick={() => setShowAdd(true)} >{translate.t("AddChatbox")}</button>
                                </div>
                            </>
                            :
                            <div className='no-chatbox'>
                                <i className="las la-comments icon"></i>
                                <div className='title'>{translate.t('ThereIsNoCheckboxYet')}</div>
                                <button className='btn blue' onClick={() => setShowAdd(true)}>{translate.t("CreateCheckbox")}</button>
                                <div className='info'>{translate.t('CreateCheckboxInfo')}</div>
                                
                            </div>
                        }     
                    </div>
                    {showAdd &&<AddChatbox callback={(addedChatbox) => chatboxAdded(addedChatbox)}  />}
                    {deleteInfo.show && <ModalNotification yesNo={true} title={translate.t("DeleteChatbox")} content={translate.t('AreYouSureYouWantToDeleteCheckbox',{chatboxName:deleteInfo.title})} rejectBtnAsLink={true} confirmBtnText={translate.t("Delete")} rejectBtnText={translate.t("CancelAction")} callback={(status) => deleteConfirmation(status)} />}


                </>
                :
                <Loading displayText={false} />                
            }
        </>
    );

}

export default ChatboxList;