import gql from 'graphql-tag';

export const GET_ACCOUNT_OPERATOR_COUNT_DATA = gql`
    query AccountGeneralData($accountID:ID){
        account(accountID:$accountID){
            operatorCount
            pricePerOperator
            currencyID
            currency{
                title
            }
        }
    }
`;
export const GET_ACCOUNT_GENERAL_DATA = gql`
    query AccountGeneralData($accountID:ID){
        account(accountID:$accountID){
            timezone
        }
        timezones{            
            timezoneID
            countryCode
            timezone
            dstOffset
        }
    }
`;
export const GET_BLOCKED_IPS = gql`
    query AllBlockedIps($accountID:ID){
        blockedIps(accountID:$accountID){
            blockedIpID
            accountID
            ip
            blockedCount
            dateAdd
        }
    }
`;
export const GET_BLOCKED_IP = gql`
    query BlockedIp($blockedIpID:ID){
        blockedIp(blockedIpID:$blockedIpID){
            blockedIpID
            accountID
            ip
            blockedCount
            dateAdd
        }
    }
`;

export const GET_CHATBOXES = gql`
    query AllChatboxes($accountID:ID){
        allChatboxes(accountID:$accountID){
            chatboxID
            name
            webUrl
            mainColor
            active
        }
    }
`;
export const GET_CHATBOX = gql`
    query Chatbox($chatboxID:ID){
        chatbox(chatboxID:$chatboxID){
            chatboxID
            name
            webUrl
            mainColor
            placement
            lang
            systemOperatorName
            systemOperatorPhoto
            chatboxOnlineTitle
            chatboxOnlineStatusTitle
            textOnlinePlaceholder
            chatboxOfflineTitle
            chatboxOfflineStatusTitle
            textOfflinePlaceholder
            active
        }
    }
`;
export const GET_APPEARANCE_DATA = gql`
    query AppearanceData($chatboxID:ID){
        chatbox(chatboxID:$chatboxID){
            chatboxID
            name
            webUrl
            mainColor
            placement
            lang
            systemOperatorName
            systemOperatorPhoto
            chatboxOnlineTitle
            chatboxOnlineStatusTitle
            textOnlinePlaceholder
            chatboxOfflineTitle
            chatboxOfflineStatusTitle
            textOfflinePlaceholder
            active
        }
        allEnabledLanguages{
            languageID
            lang
            langTitle
            title
        }
        chatboxColors{
            chatboxColorID
            mainColor
            textColor
        }
    }
`;
export const GET_LINKING_TO_WEB_DATA = gql`
    query LinkingToWebData($chatboxID:ID){
        chatbox(chatboxID:$chatboxID){
            conversationCount
        }
    }
`;
export const GET_INTRODUCTION_DATA = gql`
    query IntroductionData($chatboxID:ID){
        chatbox(chatboxID:$chatboxID){
            chatboxID
            mainColor
            lang
            systemOperatorName
            systemOperatorPhoto
            chatboxOnlineTitle
            chatboxOnlineStatusTitle
            textOnlinePlaceholder
            introductionEnabled
            introductionText
            introductionGetName
            introductionNameRequired
            introductionGetEmail
            introductionEmailRequired
            introductionGetPhone
            introductionPhoneRequired
            introductionShowGdpr
            introductionShowGdprCheckbox
            introductionGdprUrl
        }
    }
`;

export const GET_RATING_DATA = gql`
    query RatingData($chatboxID:ID){
        chatbox(chatboxID:$chatboxID){
            chatboxID
            ratingEnabled
            ratingText
            mainColor
            chatboxOnlineTitle
            chatboxOnlineStatusTitle
            systemOperatorPhoto
            systemOperatorName
            textOnlinePlaceholder
            lang
        }
    }
`;

export const EDIT_ACCOUNT_DATA = gql`
    mutation EditAccountData($editAccountData:EditAccountData) {
        editAccount(editAccountData:$editAccountData){
            accountID
        }
    }
`; 
export const ADD_EDIT_CHATBOX = gql`
    mutation AddEditChatbox($addChatboxData:AddEditChatboxData) {
        addEditChatbox(addChatboxData:$addChatboxData){
        chatboxID
        name
        webUrl
        mainColor
        placement
        lang
        systemOperatorName
        chatboxOnlineTitle
        chatboxOnlineStatusTitle
        textOnlinePlaceholder
        chatboxOfflineTitle
        chatboxOfflineStatusTitle
        textOfflinePlaceholder
        active
      }
    }
`; 

export const ADD_EDIT_BLOCKED_IP = gql`
    mutation AddEditBlockedIp($addEditIpData:AddEditIpData){
        addEditBlockedIp(addEditIpData:$addEditIpData){
            blockedIpID
            accountID
            ip
            blockedCount
            dateAdd
        }
    }
`; 

export const CHANGE_GENERAL_OPERATOR_PHOTO = gql`
    mutation ChangeGeneralOperatorPhoto($chatboxID:ID, $photo: Upload) {
        changeGeneralOperatorPhoto(chatboxID:$chatboxID, photo:$photo){
            systemOperatorPhoto
        }
    }
`; 

export const DELETE_CHATBOX = gql`
    mutation DeleteChatbox($chatboxID:ID) {
        deleteChatbox(chatboxID:$chatboxID)
    }
`; 
export const DELETE_BLOCKED_IP= gql`
    mutation DeleteBlockedIp($blockedIpID:ID) {
        deleteBlockedIp(blockedIpID:$blockedIpID)
    }
`; 

